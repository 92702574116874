import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LocalstorageService } from "./Localstorage.service";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
};

@Injectable({
  providedIn: 'root'
})
export class RequestFolioService {
  constructor(
    private http: HttpClient,
    private LocalStorage: LocalstorageService
  ) { }

  url = environment.apiUrl + '/api/folios';

  /**
   *
   * @param {any} idUser
   * @returns {Observable<any>}
   */
  getFolios(params = null): Observable<any> {
    if ( params == null ) {
      return this.http.get(this.url, httpOptions);
    } else {
      return this.http.get(this.url, params);
    }
  }

  /**
   *
   * @param params
   */
  requestFolios(params: { organizationalUnitId: number, total_folios_requested: number }) {
    return this.http.post(this.url + '/request', params);
  }

  /**
   *
   * @param params
   */
  searchAdvanceFolio(params): Observable<any> {
    return this.http.post(this.url + '/searchDate', params);
  }

  /**
   *
   * @param departmentId
   */
  department(departmentId: number) {
    return this.http.get(this.url + '/department/' + departmentId, httpOptions);
  }

  /**
   *
   * @param params Array id's folio
   */
  cancel(params: { folios_id: Array<number>, cancellation_reason: string }) {
    return this.http.post(this.url + '/cancel', params);
  }
}
