import { Injectable } from '@angular/core';
import {MetadataModel} from "../_models/metadata.model";
import {INTERNAL_SUBJECT} from "../_constants/SubjectTypeConstants";

@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  constructor() { }

  public sortMetadata(metadata: MetadataModel[], subjectType: number): MetadataModel[] {
    let metadataSorted: MetadataModel[] = metadata.sort((a,b) => {
        if (subjectType === INTERNAL_SUBJECT) {
          if (a.internalSettings == null || b.internalSettings == null) {
            return 1;
          } else {
            return a.internalSettings.position - b.internalSettings.position;
          }
        } else {
          if (a.externalSettings == null || b.externalSettings == null) {
            return 1;
          } else {
            return a.externalSettings.position - b.externalSettings.position;
          }
        }
      });

    return metadataSorted;
  }
}
