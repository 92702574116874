import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
};

@Injectable({
    providedIn: 'root'
})
export class SignDocumentService {
    generateQR: EventEmitter<any> = new EventEmitter<any>();

    url = environment.apiUrl + '/api/signDocument';

    constructor(
        private http: HttpClient
    ) { }

    /**
     *
     * @returns {Observable<any>}
     */
    public uploadEfirma(attachments): Observable<any> {
        let url = this.url + '/uploadEfirma';
        return this.http.post(url, attachments);
    }

    /**
     *
     */
    public efirmaDetails(): Observable<any> {
        let url = this.url + '/efirmaDetails';
        return this.http.get(url, httpOptions);
    }

    public signDocument(documentData) {
        let url = this.url;
        return this.http.post(url, documentData);
    }
}
