import { createFeatureSelector, createSelector } from "@ngrx/store";
import { State } from "../reducers";

export const newSubjectFeature = createFeatureSelector<State>('editor');

const getNewDocumentState = createSelector(newSubjectFeature, state => {
    return state.newDocument
});

export const getParticipants = createSelector(
  getNewDocumentState,
    (newDocument) => {
        return newDocument.participants
    }

);

export const getValidParticipants = createSelector(
  getNewDocumentState,
  (newDocument) => {
    return newDocument.validParticipants
  }
);

export const getValidMetadata = createSelector(
  getNewDocumentState,
  (newDocument) => {
    return newDocument.validDocumentMetadata
  }
);

export const getDocumentMetadata = createSelector(
  getNewDocumentState,
  (newDocument) => {
    return newDocument.metadata
  }
);

export const getDocumentFlowType = createSelector(
  getNewDocumentState,
  (newDocument) => {
    return newDocument.documentFlowType
  }
);

export const getMetadataAndParticipants = createSelector(
  getParticipants,
  getDocumentMetadata,
  getDocumentFlowType,
  (participants, newDocumentMetadata, documentFlowType) => {
    return {
      participants: participants,
      metadata: newDocumentMetadata,
      documentFlowType: documentFlowType
    }
  }
);

export const getDocument = createSelector(
  getNewDocumentState,
  (newDocument) => {
    return newDocument.document;
  }
);

export const getParticipantsFromDocument = createSelector(
  getDocument,
  (newDocument) => {
    return newDocument.participants;
  }
);

export const isLoading = createSelector(
  getNewDocumentState,
  state => {
    return state.isLoading;
  }
);

export const getCatParticipantActions = createSelector(
  getNewDocumentState,
  state => {
    return state.catParticipantActions;
  }
);

export const getRespondSubject = createSelector(
  getNewDocumentState,
  state => {
    return state.respondSubject;
  }
);
